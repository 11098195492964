const selectors = {
  oneTime: '[data-option-onetime]',
  subSave: '[data-option-subsave]',
  plansDropdown: '[data-plans-dropdown]',
  rechargeSelect: '.js-recharge-select',
  discountValue: '[data-label-discount]'
}

class widgetRecharge extends HTMLElement {
  constructor() {
    super()

    this.rechargeSelect = this.querySelector(selectors.rechargeSelect)

    this.config = {
      productId: this.dataset.prodId,
      injectionParent: this,
      injectionMethod: 'prepend',
      type: 'injected',
      injectFn: (node, target) => {
      },
      selectors: {
        price: ['.product-item__price'],
        variant: ['.product-form__variants']
      }
    };

    this.addDataToReChargeWidget()
    this.handleSelectChange()
  }

  addDataToReChargeWidget = () => {
    if (window.ReChargeWidget) {
      let is_recharge = false
      window.ReChargeWidget.api.fetchWidgetData().then((data) => {
        const {storeSettings, widgetSettings, products} = data

        this.customSelectWidget(storeSettings, widgetSettings)
      })
    } else {
      setTimeout(this.addDataToReChargeWidget, 1000);
    }
  }

  customSelectWidget = (storeSettings, widgetSettings) => {
    const {store_currency} = storeSettings
    const {onetime_message} = widgetSettings

    window.ReChargeWidget?.api?.fetchProduct(this.dataset.prodId).then((product) => {
      if (!this.rechargeSelect || !product.in_recharge) return

      const {selling_plan_groups, external_product_id} = product

      const oneTimePurchaseOption = document.createElement('option')

      window.ReChargeWidget.createWidget(this.config);

      oneTimePurchaseOption.setAttribute('data-plan-option', onetime_message)
      oneTimePurchaseOption.setAttribute('value', '')
      oneTimePurchaseOption.textContent = onetime_message
      this.rechargeSelect.appendChild(oneTimePurchaseOption)

      selling_plan_groups.forEach(group => {
        group.selling_plans.forEach(plan => {
          const {selling_plan_name, selling_plan_id, price_adjustments_value, price_adjustments_value_type} = plan
          const subscribeOption = document.createElement('option')

          subscribeOption.setAttribute('data-plan-option', selling_plan_name)
          if (price_adjustments_value_type === 'percentage') {
            subscribeOption.setAttribute('data-plan-value', `${price_adjustments_value}%`)
          }
          subscribeOption.setAttribute('value', selling_plan_id)
          subscribeOption.textContent = selling_plan_name

          this.rechargeSelect.appendChild(subscribeOption)
        });
      });

      if (this.dataset.prodAvailable === 'false') {
        this.rechargeSelect.setAttribute('disabled', 'disabled')
      }
      this.classList.remove('hidden')
    })
  }

  handleSelectChange = () => {
    if (!this.rechargeSelect) return

    const widget = this
    this.rechargeSelect.addEventListener('change', function (event) {
      const selected = event.target.value;

      const oneTime = widget.querySelector(selectors.oneTime)
      const subSave = widget.querySelector(selectors.subSave)
      const plansDropdown = widget.querySelector(selectors.plansDropdown)

      if (selected === '') {
        if (oneTime) oneTime.click()
      } else {
        if (subSave) subSave.click()

        if (plansDropdown) {
          const options = plansDropdown.options;
          for (let i = 0; i < options.length; i++) {
            if (options[i].value === selected) {
              options[i].selected = true;
              options[i].setAttribute('selected', true);
              break;
            }
          }
          plansDropdown.value = selected
        }
      }
    });
  }
}

window.customElements.define("widget-recharge", widgetRecharge)